import { GridCell } from '@almbrand/grid';
import { IframePage } from 'components/5-templates/IframePage';
import { LoginPage, MultiplePageTemplate, PageTemplate, ResultPage } from 'components/index';
import { PageNames } from 'constants/pageNames';
import { ProgressBarNames } from 'constants/progressBarNames';
import styles from '../components/5-templates/MultiplePageTemplate/MultiplePageTemplate.module.scss';
import { usePageParam } from './usePageParam';

export const useRenderPage = (currentPage: WizardPage | undefined) => {
	const { getPageParam } = usePageParam();

	switch (getPageParam()) {
		case PageNames.LoginPage:
			return <LoginPage />;
		case PageNames.ResultPage:
			return <ResultPage />;
		case PageNames.WebSphereIframe:
			return <IframePage />;
	}

	if (!ProgressBarNames[currentPage?.pageCategories?.value as keyof typeof ProgressBarNames])
		return (
			<GridCell
				desktopWidth='9col'
				className={styles.MultiplePageTemplate}
			>
				<PageTemplate
					pageData={currentPage}
					singlePage
				/>
			</GridCell>
		);

	return <MultiplePageTemplate />;
};
