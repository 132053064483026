import { ButtonProps } from '@almbrand/button';
import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { Header as HeaderComponentLibrary } from '@almbrand/header';
import classNames from 'classnames';
import { PageNames } from 'constants/pageNames';
import { usePageParam } from 'hooks/usePageParam';
import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import style from './Header.module.scss';
import codanLogo from './assets/CodanLogo.svg';

export type HeaderLink = {
	action: () => void;
	title: string;
};

export interface HeaderProps {
	onLogoutClick: () => void;
	navLinks: HeaderLink[];
	logoLink: string;
	logoUrl: string;
	loginUrl: Link;
	loginIcon: Image;
	loginLabelText: string;
}

export const Header: React.FC<HeaderProps> = ({
	onLogoutClick,
	navLinks,
	logoLink,
	logoUrl,
	loginUrl,
	loginIcon,
	loginLabelText,
}) => {
	const { setPageParam } = usePageParam();
	const containerRef = useRef<HTMLDivElement>(null);

	const isUserLoggedIn = useAppSelector((state) => state.customerParty?.data?.isUserLoggedIn);
	const buttonText = isUserLoggedIn ? 'Log ud' : loginLabelText.trim() || '';

	const onClickHandler = () => {
		isUserLoggedIn ? onLogoutClick() : setPageParam(PageNames.LoginPage);
	};

	const buttonProps: ButtonProps | undefined =
		buttonText || loginUrl.url
			? {
					children: buttonText,
					onClick: () => onClickHandler(),
					...(isUserLoggedIn ? {} : { linkButtonSettings: { href: loginUrl?.url ?? '#' } }),
					icon: {
						src: loginIcon?.url ?? '',
						altText: 'Login icon',
					},
					buttonType: 'primary',
				}
			: undefined; // buttonProps is non-mandatory in CL

	return (
		<Container
			backgroundColor='bg-white'
			desktopWidth='fullWidth'
			mobileWidth='fullWidth'
			tabletWidth='fullWidth'
			align='none'
			noPadding
		>
			<Container
				backgroundColor='bg-white'
				desktopWidth='1600'
				mobileWidth='fullWidth'
				tabletWidth='fullWidth'
				noPadding
				className={classNames(style.Header)}
			>
				<Grid ref={containerRef}>
					<GridCell
						desktopWidth='12col'
						tabletWidth='12col'
						mobileWidth='12col'
						nogutter
					>
						<HeaderComponentLibrary
							logoUrl={logoUrl ?? codanLogo}
							logoLink={logoLink}
							navigationLinks={navLinks}
							renderChildren={(link: HeaderLink) => (
								<NavLink
									to='#'
									onClick={(e) => {
										e.preventDefault(); // Prevent default navigation behavior
										link.action(); // Handle navigation via the modal
									}}
									key={link.title}
									className={({ isActive }) =>
										isActive ? 'active-link-class' : 'inactive-link-class'
									}
								>
									{link.title}
								</NavLink>
							)}
							buttonProps={buttonProps}
						/>
					</GridCell>
				</Grid>
			</Container>
		</Container>
	);
};
