import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ClaimFormItem {
	data: any;
	page: string;
}

export interface ClaimFormState {
	[key: string]: ClaimFormItem;
}

const initialState: ClaimFormState = {};

export const claimFormSlice = createSlice({
	name: 'ClaimFormSlice',
	initialState,
	reducers: {
		// Reducer to add or update an item
		addFormItem: (state, action: PayloadAction<{ key: string; item: ClaimFormItem }>) => {
			const { key, item } = action.payload;
			return {
				...state,
				[key]: item,
			};
		},
		// Reducer to remove an item
		removeFormItem: (state, action: PayloadAction<{ key: string }>) => {
			const { key } = action.payload;
			const { [key]: deletedItem, ...restOfState } = state;
			return restOfState;
		},
		// Reducer to clear the state
		clearForm: () => {
			return initialState;
		},
	},
});

// Export the action creators
export const { addFormItem, removeFormItem, clearForm } = claimFormSlice.actions;

export default claimFormSlice.reducer;
