import { ThemeSelector } from '@almbrand/themeselector';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';

import {
	AppInsightsErrorBoundary,
	initAppInsightsLogger,
	isAppInsightsInitialized,
	reactPlugin,
} from 'services/ApplicationInsightsLog';
import { PageMapper } from './components/PageMapper';
import { useAppSelector } from './store';

const App = () => {
	initAppInsightsLogger();

	const themeName = useAppSelector((state) => state.claimContent.theme);
	const renderWithAppInsights = isAppInsightsInitialized() && reactPlugin;

	const appContent = (
		<div className={styles.App}>
			<Routes>
				<Route path='/'>
					<Route
						path=':flowName'
						element={<PageMapper />}
					/>
				</Route>
			</Routes>
		</div>
	);

	return (
		<ThemeSelector themeName={themeName === 'codan' ? 'Codan-theme' : 'Almbrand-theme'}>
			{renderWithAppInsights ? (
				<AppInsightsErrorBoundary
					appInsights={reactPlugin}
					onError={() => null}
				>
					{appContent}
				</AppInsightsErrorBoundary>
			) : (
				appContent
			)}
		</ThemeSelector>
	);
};

export default App;
