export enum Rules {
	Hide = 'Hide', // Hide the component (hide a content component)
	Clear = 'Clear',
	ClearAndHide = 'ClearAndHide',
	Any = '*',
}

export enum NumberComparisonCondition {
	IsNumber = 'is_number',
	Equals = 'is_number_eq_',
	LessThan = 'is_number_lt_',
	LessThanOrEquals = 'is_number_lteq_',
	GreaterThan = 'is_number_gt_',
	GreaterThanOrEquals = 'is_number_gteq_',
}
