import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { clearForm } from 'store/slices/claimFormSlice';
import { clearPages } from 'store/slices/pageListSlice';
import { removeStateFromCookie } from 'utilities/cookieUtils';
import { removeStateFromSessionStorage } from 'utilities/sessionStorageUtils';

/**
 * Custom hook to fully reset the application state. Includes session & cookies.
 * @returns A function to reset the state.
 */
export const useResetAppState = (resetState?: boolean) => {
	const dispatch = useAppDispatch();

	const resetAppState = useCallback(() => {
		removeStateFromSessionStorage();
		removeStateFromCookie();
		dispatch(clearForm());
		dispatch(clearPages());
	}, [dispatch]);

	useEffect(() => {
		if (resetState) {
			resetAppState();
		}
	}, [resetState]);

	// Return the reset function only if no argument is provided
	if (resetState === undefined) {
		return resetAppState;
	}
};
