export enum ProgressBarNames {
	AboutTheDamage = 'Om skaden',
	DateAndTime = 'Hvor og hvornår',
	ContactInformation = 'Om dig',
	AdditionalInformation = 'Yderligere info',
	FinishTheDamage = 'Afslut skaden',
	ContactDetails = 'Kontaktinformation',
	Where = 'Hvor',
	RepairshopSelection = 'Vælg værksted',
}
