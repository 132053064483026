import { ButtonProps } from '@almbrand/button';
import { Modal } from '@almbrand/modal';
import { PageNames } from 'constants/pageNames';
import { usePageParam } from 'hooks/usePageParam';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayWarningModal } from 'store/slices/modalSlice';

export interface ReadOnlyWarningModalProps {
	title: string;
	subheading: string;
	actionText: string;
	closeText: string;
	actionButtonType: ButtonProps['buttonType'];
	closeButtonType: ButtonProps['buttonType'];
	reverseButtonOrder: boolean;
	handleActionUrl: { originalString: string };
}

export const ReadOnlyWarningModal: React.FC<ReadOnlyWarningModalProps> = ({
	title,
	subheading,
	actionText,
	closeText,
	actionButtonType,
	closeButtonType,
	reverseButtonOrder,
	handleActionUrl,
}) => {
	const { setPageParam } = usePageParam();

	const dispatch = useAppDispatch();

	const redirectToUrlOrFallback = () => {
		if (handleActionUrl?.originalString) {
			window.location.href = handleActionUrl.originalString;
		} else {
			setPageParam(PageNames.StartPage);
		}
	};

	const displayModal = useAppSelector((state) => state.modal.displayWarningModal);

	return (
		<>
			{displayModal && (
				<Modal
					title={title}
					children={subheading}
					actionText={actionText}
					handleClose={() => dispatch(displayWarningModal(false))}
					closeText={closeText}
					ariaClose='Close'
					handleAction={redirectToUrlOrFallback}
					actionButtonType={actionButtonType}
					closeButtonType={closeButtonType}
					reverseButtonOrder={reverseButtonOrder}
				/>
			)}
		</>
	);
};
