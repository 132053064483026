import { APIProvider } from '@vis.gl/react-google-maps';
import styles from './GoogleMapSearch.module.scss';
import { MapController } from './MapController';

export interface GoogleMapSearchProps extends WizardComponentBase {
	placeholder: string;
	googleMapsApiKey: string;
	googleMapsInputFieldIcon?: string;
	hasError: boolean;
	register: any;
}

export const GoogleMapSearch: React.FC<GoogleMapSearchProps> = ({
	googleMapsApiKey,
	fieldName,
	googleMapsInputFieldIcon,
	register,
	required,
	placeholder,
	allowSkip,
	allowSkipText,
	descriptionMode,
	description,
}) => {
	const { name } = register(fieldName, { required });

	return (
		<div className={styles.GoogleMapSearch}>
			<APIProvider
				apiKey={googleMapsApiKey}
				language={'da'}
				region={'DK'}
			>
				<MapController
					fieldName={name ?? 'map'}
					placeholder={placeholder}
					locationIcon={googleMapsInputFieldIcon}
					allowSkip={allowSkip}
					allowSkipText={allowSkipText}
					description={description}
					descriptionMode={descriptionMode}
				/>
			</APIProvider>
		</div>
	);
};
