import { Headline } from '@almbrand/headline';
import { RichText } from 'components/1-atoms';
import styles from './ContentContainer.module.scss';

import { replacePlaceholders } from 'utilities/placeholderReplacer';
export interface ContentContainerProps {
	headingText?: string;
	children?: string;
}

export const ContentContainer: React.FC<ContentContainerProps> = ({ headingText, children }) => {
	if (!children && !headingText) {
		return null;
	}

	return (
		<div className={styles.ContentContainer}>
			{headingText && (
				<Headline
					size='sm'
					title={replacePlaceholders(headingText)}
					variant='h3'
				/>
			)}
			{children && <RichText description={children} />}
		</div>
	);
};
