import { validateConditions } from './/validationHelpers/conditionalValidators';
import { Rules } from './validationHelpers/conditionEnums';
import { getStoreItem } from './validationHelpers/storeHandler';

export const evaluateNextUrl = (conditionalField: {
	andConditions?: string[];
	fieldId: string;
	fieldValue: string;
	id: string;
	url: string;
}) => {
	// get the main data from the conditionalField
	const { fieldId, andConditions } = conditionalField ?? {};
	let { fieldValue } = conditionalField ?? {};
	// check if fieldId is * and return true
	if (fieldValue == Rules.Any) return true;

	// check if Positive condition and remove the '!' in case its negative
	const isPositiveCondition = !fieldValue?.startsWith('!');
	if (!isPositiveCondition) fieldValue = fieldValue.substring(1);

	// getStoreItem handles both strings and objects
	const storeItem = getStoreItem(fieldId);

	// validate all conditions
	const isConditionValid = validateConditions(storeItem, fieldValue, isPositiveCondition);

	if (isConditionValid && andConditions) {
		andConditions?.map((condition) => {
			// get the store item for andCondition
			const storeItem = getStoreItem(condition);
			// validate the andCondition
			return validateConditions(storeItem, condition, isPositiveCondition);
		});
	}

	return isConditionValid;
};
