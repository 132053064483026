import { PageNames } from 'constants/pageNames';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { postDataSource } from 'services/WizardServices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPartyData, verifyUserLogin } from 'store/slices/customerPartySlice';
import { updateDynamicData } from 'store/slices/pageListSlice';
import { useNextUrl } from './useHandleNextUrl';
import { usePageParam } from './usePageParam';

export const useFetchDynamicData = (pageData?: WizardPage, methods?: any) => {
	const [dynamicData, setDynamicData] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isInitStateVisible, setIsInitStateVisible] = useState<boolean>(true);
	const [isAuthPending, setIsAuthPending] = useState<boolean>(true);
	const dispatch = useAppDispatch();

	const {
		id,
		path,
		dynamicData: storedDynamicData,
		requireAuth: pageRequireAuth,
		navigation,
		stateListDataSourceId,
	} = pageData ?? {};

	const datasourceId = pageData?.content?.[0]?.dataSourceId;
	const isPageStateList = Number(stateListDataSourceId) > 0;
	const [searchParams] = useSearchParams();

	const isUserLoggedIn = useAppSelector((state) => state.customerParty?.data?.isUserLoggedIn);

	const { getPageParam, setPageParam } = usePageParam();

	const currentPage = getPageParam();

	const navigateNextPage = async () => {
		const { getNextUrl } = useNextUrl(navigation?.nextUrls);
		const url = await getNextUrl();
		setPageParam(url?.replace('/', '') ?? '');
	};

	const actionResolver = async (actionType: ActionType, url?: string) => {
		switch (actionType) {
			case 'redirect':
				if (url) {
					window.location.href = url;
				}
				break;
			case 'redirect-internally':
				setPageParam(url?.replace('/', '') ?? '');
				break;
			case 'redirect-to-next-valid-page':
				if (isPageStateList) {
					excludeStateListFromFormValidation();
				}
				await navigateNextPage();
				break;
			default:
				break;
		}
	};

	const checkAuthentication = async () => {
		if (isUserLoggedIn) {
			return true;
		} else {
			const authResult = await dispatch(verifyUserLogin());
			return authResult.meta.requestStatus === 'fulfilled';
		}
	};

	const excludeStateListFromFormValidation = async () => {
		//1. Unregister the init_state_list trigger
		methods.unregister('init_state_list');

		//2. Tells the form to revalidate the init_state_list trigger
		await methods.trigger('init_state_list');

		//3. Uses the state to hide the field
		setIsInitStateVisible(false);
	};

	const fetchData = async (stateListUrl?: string) => {
		// Ternary operator to handle edge case where dataSourceUrl is not triggered
		// due to the usage of StateListComponent (Car flow)
		let dataSourceUrl = stateListUrl ?? pageData?.dataSourceUrl ?? pageData?.content?.[0]?.stateListUrl;

		if (!dataSourceUrl) {
			return;
		}
		setIsLoading(true);
		try {
			const cookieData = searchParams.get('init');

			const response = await postDataSource(dataSourceUrl, id, {
				datasourceId: datasourceId,
				...(cookieData ? { init: cookieData } : {}),
			});

			if (response?.action) {
				const { type, url } = response.action;
				await actionResolver(type, url);
			}

			// Duplicate "fieldValues" due to Optimizely being inconsistent with the response
			dispatch(setPartyData(response.fieldValues || response.FieldValues));
			dispatch(updateDynamicData({ pagePath: path ?? '', dynamicData: response }));
			setDynamicData(response);
		} catch (error) {
			if (isPageStateList) {
				excludeStateListFromFormValidation();
				await navigateNextPage();
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		async function initPageData() {
			if (currentPage !== path?.replace('/', '')) {
				setDynamicData(storedDynamicData ?? {});
				return;
			}

			if (pageRequireAuth) {
				const isAuthenticated = await checkAuthentication();
				if (!isAuthenticated) {
					setPageParam(PageNames.LoginPage);
					return;
				}
			}
			setIsAuthPending(false);
			fetchData();
		}

		initPageData();
	}, [currentPage, pageData?.dataSourceUrl, pageRequireAuth, path]);

	// Return empty data if auth check is still pending or if user is unauthenticated
	if (isAuthPending) {
		return { dynamicData: {}, isLoading: true, fetchData, isInitStateVisible };
	}

	return { dynamicData, isLoading, fetchData, isInitStateVisible };
};
