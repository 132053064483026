import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getClaimFlow, getClaimFlowContent } from 'services/WizardServices';

export interface ClaimContentState extends ApiState {
	theme?: string;
	contentId?: number;
	flowName?: string;
	wizardData?: WizardModel;
	frontendConfiguration?: FrontendConfigurationModel;
}

const initialState: ClaimContentState = {
	flowName: undefined,
	contentId: undefined,
	theme: undefined,
	wizardData: undefined,
	loading: false,
	error: null,
	frontendConfiguration: undefined,
};

export const fetchWizardData = createAsyncThunk('wizard/fetchData', async (flowName: string, { rejectWithValue }) => {
	try {
		const claimFlowContent = await getClaimFlowContent(flowName);
		const claimData = await claimFlowContent.mainArea?.items?.find(
			(item) => item.content.contentType == 'WizardUiBlock'
		);
		const { theme, frontendConfiguration } = claimFlowContent;

		if (!claimData?.content?.contentId) throw new Error('contentId was not found in claimData');

		const { contentId } = claimData.content;

		const wizardData = await getClaimFlow(contentId);

		return { wizardData, contentId, theme, flowName, frontendConfiguration };
	} catch (error) {
		const formattedError = error as AxiosError;
		if (!formattedError?.response) {
			throw error;
		}
		const { data, status } = formattedError.response;
		return rejectWithValue({ data, status });
	}
});

export const claimContentSlice = createSlice({
	name: 'ClaimContentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWizardData.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(
				fetchWizardData.fulfilled,
				(
					state,
					action: PayloadAction<{
						wizardData: WizardModel;
						contentId: number;
						theme: string;
						flowName: string;
						frontendConfiguration: FrontendConfigurationModel | undefined;
					}>
				) => {
					state.loading = false;
					const { contentId, theme, wizardData, flowName, frontendConfiguration } = action.payload;
					state.wizardData = wizardData;
					state.flowName = flowName;
					state.theme = theme;
					state.contentId = contentId;
					state.error = null;
					state.frontendConfiguration = frontendConfiguration;
				}
			)
			.addCase(fetchWizardData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export default claimContentSlice.reducer;
