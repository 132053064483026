import { Container } from '@almbrand/container';
import { Grid } from '@almbrand/grid';
import styles from './OverlayModel.module.scss';

export interface OverlayModelProps {
	children: React.ReactNode;
}

export const OverlayModel: React.FC<OverlayModelProps> = ({ children }) => {
	return (
		<Container
			className={styles.OverlayModel__container__outer}
			desktopWidth='1600'
			tabletWidth='fullWidth'
			mobileWidth='fullWidth'
		>
			<Container
				className={styles.OverlayModel__container__inner}
				desktopWidth='2500'
				tabletWidth='1600'
				mobileWidth='fullWidth'
			>
				<Grid
					className={styles.OverlayModel__model}
					container
					nogutter
				>
					{children}
				</Grid>
			</Container>
		</Container>
	);
};
