import classNames from 'classnames';
import { Component } from 'components/Component';
import { PageNames } from 'constants/pageNames';
import { useAppSelector } from 'store/hooks';
import styles from './IframePage.module.scss';

export interface IframePageProps {}

export const IframePage: React.FC<IframePageProps> = () => {
	const claimContent = useAppSelector((state) => state.claimContent);
	const { pages: wizardPages } = claimContent.wizardData ?? {};
	const { content, name } = wizardPages?.find((page) => page.path === '/' + PageNames.WebSphereIframe) ?? {};

	return (
		<section
			className={classNames(styles.IframePage)}
			id={name}
		>
			{content?.map((component) => {
				return (
					<Component
						key={component?.inputId}
						{...component}
					/>
				);
			})}
		</section>
	);
};
