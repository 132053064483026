import classNames from 'classnames';
import { StepIndicator } from 'components/2-molecules/StepIndicator';
import { ProgressBarNames } from 'constants/progressBarNames';
import { usePageParam } from 'hooks/usePageParam';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styles from './ProgressBar.module.scss';

export interface ProgressBarProps {
	children?: JSX.Element;
	activePages: WizardPage[];
	setActivePage: (index: number) => void;
}

type ProgressBarData = {
	value: string;
	title: string;
	id: number;
	active: boolean;
	style?: CSSProperties;
	index: number | null;
	path: string;
	show: boolean;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ children, activePages, setActivePage }) => {
	const modal = useAppSelector((state) => state.modal);
	const progressBarValues = useAppSelector((state) => state.claimContent.wizardData?.abgProgressBarOrder);

	const { getPageParam } = usePageParam();
	const currenPath = getPageParam();
	const formattedSearchString = `/${currenPath}`;

	const initProgressBarObj: ProgressBarData[] = [];
	const [progressBarObj, setProgressBarObj] = useState(initProgressBarObj);
	const progressBarRef = useRef<HTMLInputElement>(null);

	progressBarValues?.map((data) => {
		if (Object.values(ProgressBarNames).includes(ProgressBarNames[data as keyof typeof ProgressBarNames])) {
			initProgressBarObj.push({
				value: data,
				title: ProgressBarNames[data as keyof typeof ProgressBarNames],
				id: 0,
				active: false,
				style: { fontWeight: 'normal' },
				index: null,
				path: '',
				show: true,
			});
		}
	});

	useEffect(() => {
		if (activePages?.length) {
			setProgressBarObj(
				progressBarObj.map((data) => {
					const firstFoundPageData = activePages.find((p) => p.pageCategories.value === data.value);
					const index = activePages.findIndex((p) => p.pageCategories.value === data.value);

					const activePage = activePages
						.filter((p) => p.pageCategories.value === data.value)
						.find((p) => p.path === formattedSearchString);

					return {
						...data,
						style: {
							fontWeight: formattedSearchString === activePage?.path ? 'bold' : 'normal',
						},
						active: formattedSearchString === activePage?.path,
						path: firstFoundPageData?.path ?? '',
						index: index,
					};
				})
			);
		}
	}, [activePages, currenPath]);

	const goToSection = (
		index: number | null | undefined,
		event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
	) => {
		if (index === null || index === undefined || index < 0) return;

		setProgressBarObj(
			progressBarObj.map((data, index) => {
				return {
					...data,
					style: {
						fontWeight:
							Number(event.currentTarget.getAttribute('data-index')) === index ? 'bold' : 'normal',
					},
				};
			})
		);

		setActivePage(index);
	};

	return (
		<div
			ref={progressBarRef}
			className={styles.ProgressBar__wrapper}
		>
			<div className={classNames(styles.ProgressBar, modal.isModal && styles.ProgressBar___isModal)}>
				{progressBarObj && (
					<StepIndicator
						steps={progressBarObj.map(({ title, value, index }) => ({ title, value, index }))}
						currentStepIndex={progressBarObj.findIndex((p) => p.active)}
						getLiIndex={goToSection}
						longSteps={[2]}
					/>
				)}
			</div>
		</div>
	);
};
