import { critical } from 'services/ApplicationInsightsLog';

const flowName = window.location.pathname.split('/')[1];

export const loadStateFromSessionStorage = () => {
	try {
		const serializedState = sessionStorage.getItem(`redux-${flowName}-state`);
		if (serializedState === null) {
			return undefined; // No state in sessionStorage
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined; // Handle errors e.g. JSON parsing errors
	}
};

export const saveStateToSessionStorage = (state: any) => {
	try {
		const serializedState = JSON.stringify(state);
		sessionStorage.setItem(`redux-${flowName}-state`, serializedState);
	} catch (err) {
		// Handle errors e.g. sessionStorage being full
	}
};

export const removeStateFromSessionStorage = () => {
	try {
		sessionStorage.removeItem(`redux-${flowName}-state`);
	} catch (err) {
		critical(err as Error, 'Failed to remove sessionStorage');
	}
};
