import { exception } from 'services/ApplicationInsightsLog';
import { ClaimFormItem } from 'store/slices/claimFormSlice';
import { store } from 'store/store';
import { getClaimFormItemName } from './storeHandler';

/*
	This function returns if the user is changing a page whish is not the last page
	which returns if a user changed their answer no on last page
*/

export const getFormItemChanged = (path: string, formData: any): undefined | ClaimFormItem => {
	// get the current form rom the state
	const { claimForm: claimStore, pages } = store.getState();

	// check if current page is the last page, if true, no actions required.
	if (path && path === pages.lastPage) return;

	// find the question name in the formData
	const claimFormItemName = getClaimFormItemName(formData);

	// if its not in the store which should not be the case
	if (!claimFormItemName) {
		exception(
			new Error('Item is not present in the store: ' + claimFormItemName),
			'Item is not present in the store'
		);
		return;
	}

	// check if the store value is equal to the new data from the formData( we get from react-hook-form )
	if (JSON.stringify(claimStore[claimFormItemName]?.data) !== JSON.stringify(formData[claimFormItemName]))
		return claimStore[claimFormItemName];
};
