import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { usePageParam } from './usePageParam';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'touchstart'];

export const useAutoLogoutTimer = (interval: number) => {
	const isUserLoggedIn = useAppSelector((state) => state.customerParty?.data?.isUserLoggedIn);
	const [triggerAutoLogout, setTriggerAutoLogout] = useState(false);
	const { getPageParam } = usePageParam();

	const currentPage = getPageParam();
	let timer: NodeJS.Timeout;

	const triggerTimer = () => {
		timer = setTimeout(() => {
			setTriggerAutoLogout(true);
		}, interval);
	};

	const resetTimer = () => {
		if (timer) {
			clearTimeout(timer);
			setTriggerAutoLogout(false);
		}
	};

	useEffect(() => {
		if (isUserLoggedIn) {
			events.forEach((event) => {
				window.addEventListener(event, () => {
					resetTimer();
					triggerTimer();
				});
			});
		}

		return () => {
			events.forEach((event) => {
				window.removeEventListener(event, resetTimer);
			});
		};
	}, [currentPage, triggerAutoLogout]);

	return { triggerAutoLogout, triggerTimer };
};
