import { GridCell } from '@almbrand/grid';
import { InputTextField } from '@almbrand/inputtextfield';

export interface CustomInputProps {
	pattern?: string;
	value: string;
	setValue: (value: string) => void;
	inputHasError: boolean;
	setInputHasError: (hasError: boolean) => void;
	onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
	label: string;
}

export const CustomInput: React.FC<CustomInputProps> = ({
	pattern,
	value,
	setValue,
	inputHasError,
	setInputHasError,
	onFocus,
	label,
}) => {
	return (
		<GridCell
			desktopWidth='12col'
			tabletWidth='12col'
			nogutter
		>
			<GridCell
				desktopWidth='12col'
				tabletWidth='12col'
				nogutter
			>
				<InputTextField
					label={label}
					onFocus={onFocus}
					onBlur={onFocus}
					value={value}
					onChange={(e) => {
						setInputHasError(false);
						setValue(e.target.value);
					}}
					pattern={pattern}
					hasError={inputHasError}
					icon={{ isIcon: false }}
				/>
			</GridCell>
		</GridCell>
	);
};
