import { RichText as RichTextComponent } from '@almbrand/richtext';
import { replacePlaceholders } from 'utilities/placeholderReplacer';
import './RichText.scss';

export interface RichTextProps extends WizardComponentBase {
	description: string;
}

export const RichText: React.FC<RichTextProps> = ({ fieldName = '', description }) => {
	return (
		<RichTextComponent
			fieldName={fieldName}
			description={replacePlaceholders(description)}
		/>
	);
};
