import { ThemeContext } from '@almbrand/themeselector';
import { ContentContainer } from 'components';
import { useResetAppState } from 'hooks/useResetAppState';
import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styles from './ResultPage.module.scss';

export interface ResultPageProps {}

export const ResultPage: React.FC<ResultPageProps> = () => {
	const [resultPage, setResultPage] = useState<string>();
	const result = useAppSelector((state) => state.pages.resultPageHTML);
	const claimContent = useAppSelector((state) => state.claimContent);
	const { usabilla_id } = claimContent.wizardData ?? {};
	const theme = useContext(ThemeContext);
	const resetAppState = useResetAppState();

	useEffect(() => {
		if (!result) return;

		setResultPage(result);

		if (resetAppState) {
			resetAppState();
		}
	}, [result, resetAppState]);

	return (
		<section className={styles.ResultPage}>
			<ContentContainer children={resultPage} />
			{/* Placeholder for future usability enhancements (GTM etc) */}
			{/* {usabilla_id && (
				<Usabilla
					stepId='Fnol_receipt'
					usabillaWidgetId={usabilla_id}
					category={'ABG_Fnol_receipt_' + theme}
				/>
			)} */}
		</section>
	);
};
