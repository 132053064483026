import { GridCell } from '@almbrand/grid';
import { ProgressBar } from 'components/1-atoms';
import { ScrollComponent } from 'components/4-habitats/ScrollComponent/ScrollComponent';
import { PageTemplate } from 'components/5-templates/PageTemplate';
import { ProgressBarNames } from 'constants/progressBarNames';
import { usePageParam } from 'hooks/usePageParam';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import PageTemplateStyles from '../PageTemplate/PageTemplate.module.scss';
import styles from './MultiplePageTemplate.module.scss';

export interface MultiplePageTemplateProps {}

export const MultiplePageTemplate: React.FC<MultiplePageTemplateProps> = () => {
	const activePages = useAppSelector((state) => state.pages.activePages);

	const { getPageParam, setPageParam } = usePageParam();

	const [relevantActivePages, setRelevantActivePages] = useState<WizardPage[]>([]);
	const [animateIndex, setAnimateIndex] = useState<number>(-1);

	// Filter relevant pages
	useEffect(() => {
		setRelevantActivePages(
			activePages.filter(
				(page) => ProgressBarNames[page?.pageCategories?.value as keyof typeof ProgressBarNames] && page
			)
		);
	}, [activePages]);

	useEffect(() => {
		handlePageNavigation();
	}, [getPageParam(), relevantActivePages]);

	/**
	 * triggered on page query parameter
	 * handles whether to animate showing a question - scroll from top to bottom
	 */
	const handlePageNavigation = () => {
		const currenPath = getPageParam();
		const formattedSearchString = `/${currenPath}`;

		// check if pageContent is in the activePages list
		const indexOfCurrentPage = relevantActivePages.map((page) => page.path).indexOf(formattedSearchString);

		// animate when next button is pressed on an existing page ( user has gone back in flow and pressed "Next" )
		if (indexOfCurrentPage > -1 && indexOfCurrentPage < relevantActivePages.length) {
			// scroll to an existing page
			setAnimateIndex(indexOfCurrentPage);
		} else {
			// new page added, always animate on a new page
			//setAnimateIndex(activePages.length);
			setAnimateIndex(relevantActivePages.length);
		}
	};

	/**
	 * Handles the change of index in a multi-page template.
	 * Validates the child element, extracts and updates the page path.
	 */
	const handleIndexChange = (newIndex: number, child?: React.ReactNode) => {
		if (React.isValidElement(child)) {
			let currentPage = child.props?.pageData?.path || '';

			if (currentPage.startsWith('/')) {
				currentPage = currentPage.substring(1);
			}

			if (currentPage) {
				setPageParam(currentPage);
			}
		} else {
			console.warn(`Child at index ${newIndex} is not a valid React element`);
		}
	};

	return (
		<>
			<GridCell
				desktopWidth='9col'
				tabletWidth='12col'
				className={styles.MultiplePageTemplate}
			>
				<ScrollComponent
					activeElement={animateIndex}
					delay={90}
					onIndexChange={handleIndexChange}
					targetClass={PageTemplateStyles.PageTemplate}
				>
					{activePages
						?.filter((page: WizardPage, index: number) => {
							if (ProgressBarNames[page?.pageCategories?.value as keyof typeof ProgressBarNames])
								return page;
						})
						.map((page: WizardPage) => <PageTemplate pageData={page} />)}
				</ScrollComponent>
			</GridCell>
			<GridCell
				desktopWidth='3col'
				className={styles.MultiplePageTemplate_progressBarWrapper}
			>
				<ProgressBar
					activePages={relevantActivePages}
					setActivePage={(index) => {
						setAnimateIndex(index);
					}}
				/>
			</GridCell>
		</>
	);
};
