import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface GlobalModalState {
	isModal: boolean;
	displayWarningModal: boolean;
}

const initialState: GlobalModalState = {
	isModal: false,
	displayWarningModal: false,
};

export const modalSlice = createSlice({
	name: 'ModalSlice',
	initialState,
	reducers: {
		setGlobalModal: (state, action: PayloadAction<boolean>) => {
			const modal = action.payload;

			state.isModal = modal;
		},

		displayWarningModal: (state, action: PayloadAction<boolean>) => {
			state.displayWarningModal = action.payload;
		},
	},
});

export const { setGlobalModal, displayWarningModal } = modalSlice.actions;
export default modalSlice.reducer;
