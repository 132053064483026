import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PageListState {
	activePages: WizardPage[];
	lastPage?: string;
	resultPageHTML?: string;
}

const initialState: PageListState = {
	activePages: [],
	lastPage: undefined,
	resultPageHTML: undefined,
};

export const pageListSlice = createSlice({
	name: 'PageListSlice',
	initialState,
	reducers: {
		// Reducer to add a page
		addPage: (state, action: PayloadAction<WizardPage>) => {
			if (state.activePages.find((wPage) => wPage.path === action.payload.path)) {
				return {
					...state,
				};
			}

			const hasReadOnlyModal = action?.payload?.content?.some(
				(component: any) => component.componentType === 'ReadOnlyWarningModalComponent'
			);

			return {
				...state,
				activePages: [...state.activePages, { ...action.payload, hasReadOnlyModal: hasReadOnlyModal }],
				lastPage: action.payload.path,
			};
		},

		updateDynamicData: (state, action: PayloadAction<{ pagePath: string; dynamicData: object }>) => {
			const { pagePath, dynamicData } = action.payload;

			if (state.activePages) {
				// Create a new array with the updated page
				state.activePages = state.activePages.map((page) => {
					if (page.path === pagePath) {
						// Found the page to update, so create a copy with the updated intro
						return { ...page, dynamicData: dynamicData };
					}
					// Not the page we want to update, return it as is
					return page;
				});
			}
		},

		// Reducer to remove a specific page
		removePage: (state, action: PayloadAction<WizardPage>) => {
			const pages = state.activePages.filter((page) => page.id !== action.payload.id);
			return {
				...state,
				activePages: pages,
				lastPage: pages[pages.length].path,
			};
		},
		// Reducer to remove pages from an index
		removePages: (state, action: PayloadAction<number>) => {
			const pages = state.activePages.slice(0, action.payload);
			return {
				...state,
				activePages: pages,
				lastPage: pages[pages.length - 1].path,
			};
		},

		// The result page is is an html which we get back when we do a submit in page template
		addResultPage: (state, action: PayloadAction<string>) => {
			return {
				...state,
				resultPageHTML: action.payload,
			};
		},

		// Reducer to clear the state
		clearPages: () => {
			return initialState;
		},

		// Reducer to remove form-validation for a specific page
		removeRequiredFieldsFromSpecificPage: (state, action: PayloadAction<string>) => {
			const pagePath = action.payload;

			state.activePages = state.activePages.map((page) => {
				if (page.path !== pagePath || !page.content?.length) return page;

				const updatedContent = page.content.map((component) =>
					component.required ? { ...component, required: false } : component
				);

				return { ...page, content: updatedContent };
			});
		},
	},
});

// Selector to get the current page (last page in activePages)
export const selectCurrentPage = (state: { pages: PageListState }) => {
	const activePages = state.pages.activePages;
	return activePages?.length > 0 ? activePages[activePages.length - 1] : undefined;
};

// Selector to check if all components for the current page are not required
export const selectAreAllComponentsForPageNotRequired = createSelector([selectCurrentPage], (currentPage) => {
	return Array.isArray(currentPage?.content)
		? currentPage.content.every((component: any) => component.required === false)
		: false;
});

// Export the action creators
export const {
	addPage,
	removePage,
	removePages,
	clearPages,
	updateDynamicData,
	addResultPage,
	removeRequiredFieldsFromSpecificPage: removeRequiredFieldsFromSpecificPage,
} = pageListSlice.actions;

export default pageListSlice.reducer;
