import { Checkbox } from '@almbrand/checkbox';
import { InputTextField } from '@almbrand/inputtextfield';
import { ThemeContext } from '@almbrand/themeselector';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { centreOfDenmark, isDefaultLocation, MapsProposal, unKnownLocation } from '../MapController/MapController';
import styles from './AutoCompleteInput.module.scss';

export interface ClaimLocation {
	value?: string;
	latitude: number;
	longitude: number;
	country: string;
	countryCode: string;
}

export interface AutoCompleteInputProps {
	setSelectedPlace: (location: ClaimLocation) => void;
	selectedPlace?: string;
	fieldName: string;
	placeholder?: string;
	locationIcon?: string;
	defaultLocation: { lat: number; lng: number };
	getMapsProposal: (address: string) => Promise<MapsProposal>;
	allowSkip?: boolean;
	allowSkipText?: string;
}

export const AutoCompleteInput = ({
	setSelectedPlace,
	selectedPlace,
	fieldName,
	placeholder,
	locationIcon,
	defaultLocation,
	getMapsProposal,
	allowSkip,
	allowSkipText,
}: AutoCompleteInputProps) => {
	const theme = useContext(ThemeContext);
	const [inputHasError, setInputHasError] = useState(false);
	const { setValue } = useFormContext(); // retrieve hook methods

	const [skipValidation, setSkipValidation] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [updateCheckBoxKey, setUpdateCheckBoxKey] = useState('check');

	const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

	const places = useMapsLibrary('places');

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!places || !inputRef.current) return;

		const options = {
			fields: ['address_components', 'geometry', 'name', 'formatted_address'],
		};

		setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
	}, [places]);

	useEffect(() => {
		if (inputRef.current?.style) {
			inputRef.current.setAttribute('placeholder', '');
		}
		if (inputRef?.current?.parentElement) {
			inputRef.current.parentElement.style.marginLeft = '24px';
		}
	}, []);

	useEffect(() => {
		if (!placeAutocomplete) return;
		placeAutocomplete.addListener('place_changed', () => {
			const place = placeAutocomplete.getPlace();
			let country, countryCode;

			// Extract country information from address components
			(place.address_components || []).forEach((component) => {
				if (component.types.includes('country')) {
					country = component.long_name;
					countryCode = component.short_name;
				}
			});

			const lat = place.geometry?.location?.lat() ?? defaultLocation.lat;
			const lng = place.geometry?.location?.lng() ?? defaultLocation.lng;

			if (!place.formatted_address) {
				setInputHasError(true);
				return;
			}

			const claimLocation = {
				value: place.formatted_address ?? undefined,
				latitude: lat,
				longitude: lng,
				country: country ?? '',
				countryCode: countryCode ?? '',
			};

			const ok = !isDefaultLocation(claimLocation);
			setInputHasError(!ok);
			setSelectedPlace(claimLocation);
		});
	}, [placeAutocomplete]);

	useEffect(() => {
		if (!inputRef.current) return;

		if (!selectedPlace) return;

		inputRef.current.value = selectedPlace;
		if (allowSkip && selectedPlace !== allowSkipText) {
			setIsChecked(false);
			setUpdateCheckBoxKey(updateCheckBoxKey + '1');
		}

		if (inputRef.current.value) {
			setInputHasError(false);
		}
	}, [selectedPlace]);

	const getClaimLocation = (): ClaimLocation => {
		return {
			value: inputRef?.current?.value,
			latitude: defaultLocation.lat,
			longitude: defaultLocation.lng,
			country: '',
			countryCode: '',
		};
	};

	const validate = async () => {
		let error = true;
		const claimLocation = getClaimLocation();

		if (claimLocation.value) {
			const latLng = await getMapsProposal(claimLocation.value);
			claimLocation.latitude = latLng.lat;
			claimLocation.longitude = latLng.lng;

			if (!isDefaultLocation(claimLocation)) {
				claimLocation.value = latLng.formattedAddress;
				error = false;
			} else {
				claimLocation.value = undefined; // force update
			}
		}
		setInputHasError(error);

		setSelectedPlace(claimLocation);
	};

	const invalidateField = () => {
		setValue(fieldName, undefined, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};
	const onChange = async () => {
		if (inputRef?.current?.value) {
			setIsChecked(false);
			setUpdateCheckBoxKey(updateCheckBoxKey + '1');
			const latLng = await getMapsProposal(inputRef.current.value);
			if (isDefaultLocation({ latitude: latLng.lat, longitude: latLng.lng } as ClaimLocation)) {
				invalidateField();
			}
		} else {
			invalidateField();
		}
	};

	const handleSkipChange = (checked: boolean) => {
		setSkipValidation(checked);
		if (checked) {
			const skipLocation: ClaimLocation = {
				value: allowSkipText,
				latitude: unKnownLocation.lat,
				longitude: unKnownLocation.lng,
				country: '',
				countryCode: '',
			};

			setSelectedPlace(skipLocation);
		} else {
			const resetLocation: ClaimLocation = {
				value: ' ',
				latitude: centreOfDenmark.lat,
				longitude: centreOfDenmark.lng,
				country: '',
				countryCode: '',
			};

			setSelectedPlace(resetLocation);
			if (inputRef?.current) {
				setValue(fieldName, ' ', {
					shouldValidate: true,
					shouldDirty: true,
				});
				inputRef.current.focus();
				inputRef.current.blur();
			}
		}
	};

	return (
		<>
			<div
				className={styles.AutoCompleteInput}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						event.preventDefault();
						event.stopPropagation();
						validate();
					}
				}}
			>
				{locationIcon && (
					<img
						src={locationIcon}
						alt='location icon'
						className={styles.AutoCompleteInput__locationIcon}
					/>
				)}
				<InputTextField
					icon={{
						iconProp: { themeName: theme },
					}}
					ref={inputRef}
					label={placeholder}
					required={!skipValidation}
					hasError={inputHasError}
					errorMessage='Vælg adressen fra listen eller klik på kortet'
					value={selectedPlace}
					onBlur={validate}
					onChange={onChange}
				/>
			</div>
			{allowSkip && allowSkipText && (
				<Checkbox
					key={updateCheckBoxKey}
					className={styles.AutoCompleteInput__checkBox}
					name={fieldName + '2'}
					value={allowSkipText}
					label={allowSkipText ?? ''}
					onChange={(e) => handleSkipChange(e.target.checked)}
					checked={isChecked}
				/>
			)}
		</>
	);
};
