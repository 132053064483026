import { getStoreItem } from './validationHelpers/storeHandler';

/**
 * Replaces placeholders in the provided text with values from the store. Placeholders
 * are expected to be in the format {placeholder}.
 *
 * @param text - The string containing placeholders to be replaced.
 * @returns The string with placeholders replaced by store values.
 */
export const replacePlaceholders = (text: string): string => {
	const placeholderRegex = /{([\w.\-¤&;]+)}/g;

	if (!text) return '';

	return text.replace(placeholderRegex, (match, key) => {
		const [baseKey, defaultValue] = parsePlaceholderKey(key);
		const value = resolveValueFromStore(baseKey, defaultValue);
		return applyCapitalization(value, key);
	});
};

/**
 * Parses the placeholder key to extract the base key and the default value.
 *
 * @param key - The key string to parse.
 * @returns A tuple containing the base key and the default value.
 */
const parsePlaceholderKey = (key: string): [string, string | null] => {
	const [baseKey, defaultValue] = splitDefaultValue(key);
	return [baseKey.toLowerCase(), defaultValue];
};

/**
 * Splits the key into a base key and a default value if a delimiter is present.
 *
 * @param key - The key string to split.
 * @returns A tuple containing the base key and the default value.
 */
const splitDefaultValue = (key: string): [string, string | null] => {
	let delimiter;
	if (key.includes('¤')) {
		delimiter = '¤';
	} else if (key.includes('&curren;')) {
		delimiter = '&curren;';
	} else {
		delimiter = null;
	}
	return delimiter ? (key.split(delimiter) as [string, string]) : [key, null];
};

/**
 * Applies capitalization to the value based on the original key's capitalization pattern.
 *
 * @param value - The value to capitalize.
 * @param originalKey - The original key used to determine capitalization pattern.
 * @returns The capitalized value.
 */
const applyCapitalization = (value: string, originalKey: string): string => {
	if (value === '') return value;

	const isCapitalized = (char: string) => char === char.toUpperCase();
	const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

	if (isCapitalized(originalKey[0])) {
		if (originalKey.length > 1 && isCapitalized(originalKey[1])) {
			return value.toUpperCase();
		}
		return capitalize(value);
	}
	return value;
};

/**
 * Resolves a value from the store based on the item key. If the key can be split into
 * a field name and a property, it tries to fetch the value associated with that key.
 * If no specific property is sought or not found, it returns the default value.
 *
 * @param itemKey - The key of the item to resolve from the store.
 * @param defaultValue - The default value to use if the item is not found.
 * @returns The resolved value from the store or the default value.
 */
const resolveValueFromStore = (itemKey: string, defaultValue: string | null): string => {
	const [fieldName, prop] = splitDefaultValue(itemKey);

	if (!prop) {
		const value = getStoreItem(fieldName);
		return value !== undefined && value !== '' ? value : (defaultValue ?? '');
	}

	const value = getStoreItem(itemKey);
	const label = getStoreItem(`${fieldName}.label`);

	return value ?? label ?? defaultValue ?? '';
};
