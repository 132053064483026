import { NumberComparisonCondition } from './conditionEnums';

const getNumberFromCondition = (fieldValue: string, fieldNumberPrefix: string): number => {
	if (fieldValue.startsWith(fieldNumberPrefix)) {
		const numPart = fieldValue.substring(fieldNumberPrefix.length);
		return +numPart;
	}
	return NaN;
};

const validateNumberCondition = (fieldValue: string, storeItem: number): boolean => {
	if (isNaN(storeItem)) return false;

	// get all items from the enum
	const keys = Object.keys(NumberComparisonCondition);

	return keys.some((conditionKey) => {
		const condition = NumberComparisonCondition[conditionKey as keyof typeof NumberComparisonCondition];

		if (!condition || !fieldValue.startsWith(condition)) {
			return false;
		}

		const theNumber = getNumberFromCondition(fieldValue, condition);

		if (!isNaN(theNumber)) return false;

		switch (condition) {
			case NumberComparisonCondition.IsNumber:
				return true;
			case NumberComparisonCondition.Equals:
				return storeItem == theNumber;
			case NumberComparisonCondition.LessThan:
				return storeItem < theNumber;
			case NumberComparisonCondition.LessThanOrEquals:
				return storeItem <= theNumber;
			case NumberComparisonCondition.GreaterThan:
				return storeItem > theNumber;
			case NumberComparisonCondition.GreaterThanOrEquals:
				return storeItem >= theNumber;
			default:
				return false;
		}
	});
};

export const validateConditions = (storeItem: string, fieldValue: string, isPositiveCondition: boolean): boolean => {
	/* validating fieldValue in case is_empty, is_number and if fieldValue matches the one in the store.
	   will return whatever the rule expect, isPositiveCondition is what the rule result is set in the cms.
	*/
	if (fieldValue == 'is_empty' && !storeItem) return isPositiveCondition;

	if (fieldValue.startsWith('is_number')) {
		const isNumberCondition = validateNumberCondition(fieldValue, +storeItem);

		// if its not negative then its positive
		return isNumberCondition && isPositiveCondition;
	}

	// if fieldValue not equal to the storeItem
	if (!isPositiveCondition && fieldValue != storeItem) return true;

	// if the fieldValue is equal to the storeItem then return true
	return fieldValue == storeItem && isPositiveCondition;
};
