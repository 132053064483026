/**
 * This component, `StateList`, is more or less a direct copy of the `RadioList` component.
 * It was duplicated for usage in the car flow where we currently need a StateList component,
 * but don't yet have one. Instead of reusing and continuously extending the `RadioList` component,
 * we opted to create a separate `StateList` component to make things easier and keep the components distinct.
 *
 * This separation helps us avoid unnecessary complexity and allows us to tailor the behavior to the
 * specific needs of the car flow without affecting the `RadioList`.
 */

import { useAppDispatch, useAppSelector } from 'store/hooks';
import styles from './StateList.module.scss';

import { Grid, GridCell } from '@almbrand/grid';
import { RadioCard } from '@almbrand/radiocard';
import React, { useContext, useEffect, useState } from 'react';

import { LoadingSpinner } from '@almbrand/loadingspinner';
import { ThemeContext } from '@almbrand/themeselector';
import { useFetchDynamicData } from 'hooks/useFetchDynamicData';
import { resetPartyData } from 'store/slices/customerPartySlice';
import { CustomInput } from './CustomInput';

export interface StateItem extends WizardBaseListItem {
	defaultSelected?: boolean;
	text?: string;
}

export interface StateListProps extends WizardBaseList {
	label: string;
	allowCustomSelectItem: boolean;
	customSelectItemTag: string;
	customSelectItemLabel: string;
	selectItems: StateItem[];
	hasError?: boolean;
	register: any;
	submitQuestion?: () => void;
	setLoadingState?: (isLoading: boolean) => void;
}

export const StateList: React.FC<StateListProps> = ({
	fieldName = '',
	selectItems,
	required,
	label,
	validationErrorFormatText,
	hasError,
	register,
	submitQuestion,
	allowCustomSelectItem = false,
	customSelectItemLabel = 'Andet',
	customSelectItemTag = 'OTHER',
	regexValidation,
	setLoadingState,
}) => {
	const fieldWatch = useAppSelector((store) => store.claimForm[fieldName]);

	const [currentRadioState, setCurrentRadioState] = useState<number>();

	const [inputValue, setInputValue] = useState('');
	const [inputHasError, setInputHasError] = useState(false);

	const theme = useContext(ThemeContext);
	const { isLoading, fetchData } = useFetchDynamicData();

	const dispatch = useAppDispatch();

	const restoreInitialClaimChoice = () => {
		dispatch(resetPartyData());
	};

	useEffect(() => {
		if (setLoadingState) {
			setLoadingState(isLoading);
		}
	}, [isLoading, setLoadingState]);

	useEffect(() => {
		const radioItem = fieldWatch?.data;
		if (radioItem?.index === undefined || radioItem?.index === null) return;
		if (currentRadioState !== radioItem.index) {
			handleOnChange(radioItem);
		}
		if (radioItem.tag == customSelectItemTag) {
			setInputValue(radioItem?.value);
		}
	}, [fieldWatch]);

	const { onChange: onRadioChange, name: radioName } = register(fieldName, {
		required,
	});

	const isMatch = (value: string, pattern: RegExp): boolean => {
		return pattern.test(value);
	};

	const handleOnChange = async (radioItem: StateItem) => {
		setCurrentRadioState(radioItem.index);

		await handleRadioItemSelection(radioItem);

		onRadioChange({
			target: {
				name: radioName,
				value: {
					value: radioItem.value,
					label: radioItem.label,
					tag: radioItem.tag,
					index: radioItem.index,
				},
			},
		});
	};

	const handleRadioItemSelection = async (radioItem: StateItem) => {
		try {
			const NEW_CASE = 'NEW_CASE';

			if (radioItem.tag == customSelectItemTag) {
				if (!radioItem?.value || !isMatch(radioItem.value, new RegExp(regexValidation ?? ''))) {
					setInputHasError(true);
				}
			}

			if (radioItem.tag !== customSelectItemTag) {
				setInputValue('');
				setInputHasError(false);
			}

			// Restore initialState if user selects NEW_CASE
			if (radioItem.value === NEW_CASE) {
				return restoreInitialClaimChoice();
			}

			// Call Opti API if the radio item value is not NEW_CASE
			if (radioItem.value !== NEW_CASE) {
				await fetchData(radioItem.value);
			}
		} catch (error) {}
	};

	const handleFocusEvent = (e: React.FocusEvent<HTMLInputElement>) => {
		handleOnChange({
			value: e.target.value,
			label: customSelectItemLabel,
			tag: customSelectItemTag,
			index: selectItems.length,
		});
	};

	return (
		<Grid container>
			<p>{label}</p>
			{isLoading ? (
				<GridCell
					desktopWidth='12col'
					tabletWidth='12col'
					nogutter
					textAlign='center'
				>
					<LoadingSpinner
						style='large'
						type='dottedCircle'
						color='primary'
					/>{' '}
					{/* Display the spinner if loading */}
				</GridCell>
			) : (
				<>
					{selectItems?.map((radioItem, index) => (
						<GridCell
							key={radioItem.index}
							desktopWidth='12col'
							tabletWidth='12col'
							nogutter
						>
							<RadioCard
								themeName={theme}
								title={radioItem.label}
								subTitle={radioItem?.text}
								value={index.toString()}
								onChange={() => {
									handleOnChange(radioItem);
									submitQuestion && !allowCustomSelectItem && submitQuestion();
								}}
								name={radioName}
								checked={currentRadioState === radioItem.index}
								onClick={() => {
									if (currentRadioState === radioItem.index) {
										submitQuestion && !allowCustomSelectItem && submitQuestion();
									}
								}}
							/>
						</GridCell>
					))}

					{(allowCustomSelectItem || !selectItems) && (
						<CustomInput
							label={customSelectItemLabel}
							onFocus={handleFocusEvent}
							value={inputValue}
							pattern={regexValidation}
							inputHasError={inputHasError}
							setInputHasError={setInputHasError}
							setValue={setInputValue}
						/>
					)}
				</>
			)}

			{(hasError || inputHasError) && (
				<p className={styles.RadioList__errorMessage}> {validationErrorFormatText} </p>
			)}
		</Grid>
	);
};
