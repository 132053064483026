import axios, { AxiosInstance } from 'axios';
import { store } from 'store/store';
import { critical } from './ApplicationInsightsLog';

export const apiInstance = (): AxiosInstance => {
	const axiosInstance = axios.create({
		timeout: 100000,
	});

	// add X-Site-Name header to requests
	const theme = store.getState().claimContent?.theme?.toUpperCase() ?? 'CODAN';
	axios.defaults.headers.common['X-Site-Name'] = theme;

	// Add the error handling interceptor
	axiosInstance.interceptors.response.use(
		(response) => response.data,
		(error) => {
			critical(error as Error, 'API error');
			throw error;
		}
	);

	return axiosInstance;
};
