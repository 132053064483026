import { Button, ButtonProps } from '@almbrand/button';

export interface MitIdButtonProps {
	connectionId?: string;
	applicationId?: string;
	returnUrl?: string;
	errorUrl?: string;
	buttonType?: ButtonProps['buttonType'];
	children: string;
}

export const MitIdButton: React.FC<MitIdButtonProps> = ({
	connectionId,
	applicationId,
	returnUrl,
	errorUrl,
	buttonType,
	children,
}) => {
	const baseAuthPath = '/auth/login';

	// An array of all queries
	const queryParams = [
		`connectionId=${connectionId}`,
		returnUrl ? `returnUrl=${encodeURI(returnUrl)}` : `returnUrl=${encodeURI(window.location.pathname)}`,
		errorUrl ? `errorPage=${encodeURI(errorUrl)}` : `errorPage=${encodeURI('/login-error')}`,
		`applicationId=${applicationId}`,
	].join('&');

	// baseAuthPath combined with the queries
	const mitIdLink = `${baseAuthPath}?${queryParams}`;

	return (
		<Button
			buttonType={buttonType}
			linkButtonSettings={{ href: mitIdLink }}
		>
			{children}
		</Button>
	);
};
