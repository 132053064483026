import { RefObject, useEffect, useState } from 'react';

type Settings = {
	delay?: number;
	element?: RefObject<any>;
	targetClass?: string;
};

type State = {
	direction: 'UP' | 'DOWN' | undefined;
	scroll: boolean;
};

export const useScrollDirection = (settings?: Settings) => {
	const [state, setState] = useState<State>({
		direction: undefined,
		scroll: false,
	});

	const start = {
		x: 0,
		y: 0,
	};

	let targetClass: string;

	if (settings?.targetClass) {
		targetClass = settings.targetClass
			.split(' ')
			.map((string) => `.${string}`)
			.join(' ');
	}

	useEffect(() => {
		/**
		 * Adds event listeners for wheel and touch events to detect scrolling direction.
		 * The scroll events are debounced to limit state updates.
		 */
		let timeout = false; //Debouncer
		const handleWheel = (e: WheelEvent) => {
			const delta = e.deltaY;

			if (Math.abs(delta) >= (settings?.delay || 20) && !timeout) {
				timeout = true;
				setState(buildStateObject(delta));

				setTimeout(() => (timeout = false), 1000);
			}
		};

		/**
		 * Captures the starting position of a touch event.
		 */
		const handleTouchStart = (e: TouchEvent) => {
			start.x = e.touches[0].pageX;
			start.y = e.touches[0].pageY;
		};

		/**
		 * Detects the offset of a touch movement to determine scroll direction.
		 */
		const handleTouchEnd = (e: TouchEvent) => {
			const offset = {
				x: 0,
				y: 0,
			};

			offset.x = start.x - e.changedTouches[0].pageX;
			offset.y = start.y - e.changedTouches[0].pageY;

			setState(buildStateObject(offset.y));
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('touchstart', handleTouchStart);
		window.addEventListener('touchend', handleTouchEnd);

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchend', handleTouchEnd);
		};
	}, []);

	/**
	 * Checks whether the given element overflows (scrollable) or is at the top/bottom boundary.
	 * This determines if snapping behavior is allowed.
	 */
	const checkIfOverflowForAnimation = (e: Element): boolean => {
		if (checkForUndefined(e)) return true;

		const visibleElement = e.querySelector(targetClass);

		if (!visibleElement) {
			return true; // Defaulting to true to allow snapping
		}

		// Overflow detection
		const clientHeight = visibleElement.clientHeight;
		const scrollHeight = visibleElement.scrollHeight;
		const scrollTop = visibleElement.scrollTop;

		// Overflow conditions
		const isScrollable = scrollHeight > clientHeight;
		const isAtTop = scrollTop <= 0;
		const isAtBottom = scrollHeight - clientHeight <= Math.ceil(scrollTop);

		// Allow snapping only if not scrollable or at the boundaries
		return !isScrollable || isAtTop || isAtBottom;
	};

	/**
	 * Builds the state object based on the scroll offset (delta).
	 * Determines the scroll direction ('UP' or 'DOWN') and whether scrolling is active.
	 */
	const buildStateObject = (offsetY: number): State => {
		return {
			direction:
				Math.abs(offsetY) < (settings?.delay || 20)
					? undefined
					: offsetY < 0 && checkIfOverflowForAnimation(settings?.element?.current)
						? 'UP'
						: checkIfOverflowForAnimation(settings?.element?.current)
							? 'DOWN'
							: undefined,
			scroll: Math.abs(offsetY) < (settings?.delay || 20) ? false : true,
		};
	};

	/**
	 * Validates the provided element and the target class selector.
	 * Logs warnings if the element or targetClass is undefined.
	 */
	const checkForUndefined = (e: Element) => {
		switch (true) {
			case e === undefined:
				console.log('WARNING:: Add a custom element prop');
				return true;
			case e.querySelector(targetClass || 'show') === undefined:
				console.log('WARNING:: Add a custom tagetClass prop');
				return true;
		}
	};

	return { state };
};
