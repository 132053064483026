import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface GlobalCssClassState {
	addClass: boolean;
}

const initialState: GlobalCssClassState = {
	addClass: false,
};

export const addCssClassSlice = createSlice({
	name: 'AddCssClassSlice',
	initialState,
	reducers: {
		setGlobalCssClassState: (state, action: PayloadAction<boolean>) => {
			const addClass = action.payload;

			state.addClass = addClass;
		},
	},
});

export const { setGlobalCssClassState } = addCssClassSlice.actions;
export default addCssClassSlice.reducer;
