import { Button } from '@almbrand/button';
import { FormWrapper } from '@almbrand/formwrapper';
import { Grid } from '@almbrand/grid';
import { LoadingSpinner } from '@almbrand/loadingspinner';
import { Paragraph } from '@almbrand/paragraph';
import classNames from 'classnames';
import { ContentContainer } from 'components';
import { QuestionChangeModal } from 'components/3-organisms';
import { Component } from 'components/Component';
import { useFetchDynamicData } from 'hooks/useFetchDynamicData';
import { useFormSubmit } from 'hooks/useFormSubmit';
import { useNextUrl } from 'hooks/useHandleNextUrl';
import { usePageParam } from 'hooks/usePageParam';
import { useResetAppState } from 'hooks/useResetAppState';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { selectAreAllComponentsForPageNotRequired } from 'store/slices/pageListSlice';
import { shouldRenderComponent } from 'utilities/componentHelpers';
import { evaluateConditions, executeCondition } from 'utilities/conditionalFieldsValidator';
import styles from './PageTemplate.module.scss';

export interface PageTemplateProps {
	pageData?: WizardPage;
	singlePage?: boolean;
}

export const PageTemplate: React.FC<PageTemplateProps> = ({ pageData, singlePage }) => {
	const methods = useForm({ mode: 'onTouched' });

	const {
		handleSubmit,
		formState: { errors, isValid },
		register,
	} = methods;

	const { setPageParam } = usePageParam();

	const { heading, intro, content, conditionalFields, navigation, name, path, resetState } = pageData ?? {};

	const { nextButton, nextUrls, complementaryNextButton } = navigation ?? {};

	const { getNextUrl } = useNextUrl(nextUrls);

	const { dynamicData, isLoading: isDynamicDataLoading, isInitStateVisible } = useFetchDynamicData(pageData, methods);

	useResetAppState(resetState ?? false);

	const appLabel = useAppSelector((state) => state.claimContent.wizardData?.fnolAppLabel);

	const [stateListLoading, setStateListLoading] = useState<boolean>(false);

	const areAllComponentsNotRequired = useAppSelector(selectAreAllComponentsForPageNotRequired);

	const isFormValidForCurrentPage = isValid || (areAllComponentsNotRequired && pageData?.hasReadOnlyModal);

	const setNextUrl = async () => {
		const url = await getNextUrl();
		setPageParam(url?.replace('/', '') ?? '');
	};

	const {
		displayModal,
		handleModal,
		isLoading: isFormSubmitLoading,
		changedItem,
		onSubmit,
	} = useFormSubmit({
		path: path ?? '',
		submitUrl: nextButton?.submitUrl,
		setNextUrl,
		pageId: pageData?.id,
		disableChangeModal: pageData?.disableChangeModal ?? false,
	});

	const findMatchingCondition = (componentFieldName: string) => {
		if (!conditionalFields) return;
		return conditionalFields.find((condition) => {
			return evaluateConditions(componentFieldName, condition);
		});
	};

	const visibilityMap = {
		init_state_list: isInitStateVisible,
	};

	return (
		<section
			className={classNames(
				styles.PageTemplate,
				singlePage && !isDynamicDataLoading && !isFormSubmitLoading && styles.PageTemplate_sinlePage
			)}
			id={name}
		>
			{!isDynamicDataLoading && !isFormSubmitLoading && (
				<>
					{appLabel && (
						<div className={styles.PageTemplate__topContent}>
							<Paragraph
								paragraphType={{
									kind: 'label',
									size: 'sm',
								}}
								text={appLabel}
							/>
						</div>
					)}
					<div className={styles.PageTemplate_contentWrapper}>
						<ContentContainer
							children={intro}
							headingText={heading}
						/>
						{displayModal && (
							<QuestionChangeModal
								setModalOpen={handleModal}
								pageData={pageData}
								changedItem={changedItem}
								onChangeSuccessful={async () => await setNextUrl()}
							/>
						)}
						<FormProvider {...methods}>
							<FormWrapper onSubmit={handleSubmit(onSubmit)}>
								<div className={styles.PageTemplate_componentsWrapper}>
									{content?.map((component) => {
										const { actionStr } = findMatchingCondition(component.fieldName) ?? {};

										const updatedComponentProps = {
											...component,
											selectItems: dynamicData[component?.fieldName] ?? component?.selectItems,
											register: register,
											hasError: !!errors?.[component?.fieldName],
											submitQuestion: !nextButton ? () => handleSubmit(onSubmit)() : null,
											setStateListLoading,
											pageName: pageData?.path,
										};

										if (!shouldRenderComponent(component, visibilityMap)) {
											return null;
										}

										if (component?.fieldName == 'license_plate_mixed') {
											// A hack to cause the cms makes the RadioListComponent an InputComponent when allowCustomSelectItem is false
											updatedComponentProps.componentType = 'RadioListComponent';
										}

										return actionStr ? (
											executeCondition(actionStr) && (
												<Component
													{...updatedComponentProps}
													key={component?.inputId}
												/>
											)
										) : (
											<Component
												{...updatedComponentProps}
												key={component?.inputId}
											/>
										);
									})}
								</div>

								{(nextButton || (complementaryNextButton && complementaryNextButton.length > 0)) && (
									<div className={classNames(styles.PageTemplate__navigationButtons)}>
										{nextButton && (
											<Button
												type='submit'
												children={nextButton.label}
												disabledWithSubmit={!isFormValidForCurrentPage || stateListLoading}
												disabled={!isFormValidForCurrentPage || stateListLoading}
											/>
										)}

										{complementaryNextButton?.map((button, index) => {
											const { actionStr } = findMatchingCondition(button.fieldName ?? '') ?? {};

											return actionStr ? (
												executeCondition(actionStr) && (
													<Button
														key={index + 'btn'}
														type='button'
														children={button.label}
														buttonType='link'
														linkButtonSettings={{
															href: button.buttonUrl,
															target: '_self',
														}}
													/>
												)
											) : (
												<Button
													key={index + 'btn'}
													type='button'
													children={button.label}
													buttonType='link'
													linkButtonSettings={{
														href: button.buttonUrl,
														target: '_self',
													}}
												/>
											);
										})}
									</div>
								)}
							</FormWrapper>
						</FormProvider>
					</div>
				</>
			)}

			{(isDynamicDataLoading || isFormSubmitLoading) && (
				<Grid
					horizontalCenter
					verticalAligment='center'
					container
					className={styles.PageTemplate_spinnerWrapper}
				>
					<LoadingSpinner
						style='large'
						type='dottedCircle'
						color='primary'
					/>
				</Grid>
			)}
		</section>
	);
};
