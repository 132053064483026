import { Iframe } from '@almbrand/iframe';

export interface IframeComponentProps {
	componentTypeId: number | string;
	height?: string;
	iframeTitle: string;
	iframeUrl: string;
	width?: string;
}

export const IframeComponent: React.FC<IframeComponentProps> = ({
	componentTypeId,
	height,
	iframeTitle,
	iframeUrl,
	width,
}) => {
	return (
		<Iframe
			id={componentTypeId}
			title={iframeTitle}
			src={iframeUrl}
			width={width}
			height={height}
		/>
	);
};
