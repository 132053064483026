import { Checkbox } from '@almbrand/checkbox';
import { GridCell } from '@almbrand/grid';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayWarningModal } from 'store/slices/modalSlice';
import { removeRequiredFieldsFromSpecificPage } from 'store/slices/pageListSlice';

interface ReadOnlyCheckboxListProps {
	readOnlyFieldData: any[];
	inputId?: string;
	name: string;
	readonlyFieldPagePath: string;
}

const ReadOnlyCheckboxList: React.FC<ReadOnlyCheckboxListProps> = ({
	readOnlyFieldData,
	inputId,
	name,
	readonlyFieldPagePath,
}) => {
	const dispatch = useAppDispatch();

	const displayModal = useAppSelector((state) => state.modal.displayWarningModal);

	const handleDisabledCheckboxClick = (event: React.MouseEvent | React.KeyboardEvent) => {
		event.preventDefault();
		dispatch(displayWarningModal(true));
	};

	useEffect(() => {
		if (readonlyFieldPagePath && Array.isArray(readOnlyFieldData) && readOnlyFieldData?.length > 0) {
			dispatch(removeRequiredFieldsFromSpecificPage(readonlyFieldPagePath));
		}
	}, [readOnlyFieldData, readonlyFieldPagePath, dispatch]);

	return (
		<>
			{displayModal}
			{readOnlyFieldData.map((checkBoxItem: any, index: React.Key | null | undefined) => (
				<GridCell
					desktopWidth={readOnlyFieldData.length > 1 ? '6col' : '12col'}
					nogutter
					key={index}
				>
					<div
						onClick={handleDisabledCheckboxClick}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ') {
								handleDisabledCheckboxClick(e);
							}
						}}
						role='button'
						tabIndex={0}
						style={{ cursor: 'pointer' }}
					>
						<Checkbox
							name={name}
							id={inputId?.toString()}
							value={checkBoxItem.value}
							label={checkBoxItem.label}
							onClick={handleDisabledCheckboxClick}
							checked
							disabled
						/>
					</div>
				</GridCell>
			))}
		</>
	);
};

export default ReadOnlyCheckboxList;
