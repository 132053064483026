export enum PageNames {
	LoginPage = 'nemlogin',
	ResultPage = 'afslut',
	StartPage = 'velkommen',
	ResultErrorPage = 'afslutfejl',
	WebSphereIframe = 'iframe',

	OpenPages = 'https://www.codan.dk',
	FileClaim = 'https://www.codan.dk/privat/anmeldskade',
	Policies = 'https://www.sit-mit.codan.dk/mitcodan/forsikring/mineforsikringer/',
	Documents = 'https://www.sit-mit.codan.dk/mitcodan/dokumenter/',
	More = 'https://www.sit-mit.codan.dk/mitcodan/mere/',
}
