import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { validateToken } from 'services/AuthTokenServices';

export interface CustomerPartyState extends ApiState {
	data: {
		isUserLoggedIn: boolean;
		partner?: string;
		partner_brand?: string;
		customer_id?: string;
		phone_number?: string;
		customer_email?: string;
		brand_phone_no?: string;
		usabilla_id?: string;
		[key: string]: any;
	};
}

const initialState: CustomerPartyState = {
	data: {
		isUserLoggedIn: false,
		partner: undefined,
		partner_brand: undefined,
		customer_id: undefined,
		phone_number: undefined,
		customer_email: undefined,
		brand_phone_no: undefined,
		usabilla_id: undefined,
	},
	loading: false,
	error: null,
};

export const verifyUserLogin = createAsyncThunk('token/validateToken', async (arg, { rejectWithValue }) => {
	try {
		return await validateToken();
	} catch (error) {
		const formattedError = error as AxiosError;
		if (!formattedError?.response) {
			throw error;
		}
		const { data, status } = formattedError.response;
		return rejectWithValue({ data, status });
	}
});

export const customerPartySlice = createSlice({
	name: 'CustomerPartySlice',
	initialState,
	reducers: {
		setPartyData: (state, action: PayloadAction<any>) => {
			const fieldValues = action.payload;
			if (!fieldValues) return state;

			state.data = { ...state.data, ...fieldValues };
		},

		resetPartyData: (state) => {
			const { isUserLoggedIn, customer_id } = state.data;
			state.data = { ...initialState.data, isUserLoggedIn, customer_id };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(verifyUserLogin.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(verifyUserLogin.fulfilled, (state) => {
			state.loading = false;
			state.data.isUserLoggedIn = true;
			state.error = null;
		});
		builder.addCase(verifyUserLogin.rejected, (state, action) => {
			state.loading = false;
			state.data.isUserLoggedIn = false;
			state.error = action.payload;
		});
	},
});
export const { setPartyData, resetPartyData } = customerPartySlice.actions;

export default customerPartySlice.reducer;
