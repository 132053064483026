import { combineReducers } from '@reduxjs/toolkit';
import { DESTROY_SESSION } from './actionTypeConstants';
import addCssClassSlice, { GlobalCssClassState } from './slices/addCssClassSlice';
import claimContentSlice, { ClaimContentState } from './slices/claimContentSlice';
import claimFormSlice, { ClaimFormState } from './slices/claimFormSlice';
import customerPartySlice, { CustomerPartyState } from './slices/customerPartySlice';
import modalSlice, { GlobalModalState } from './slices/modalSlice';
import pageListSlice, { PageListState } from './slices/pageListSlice';

export interface State {
	claimContent: ClaimContentState;
	customerParty: CustomerPartyState;
	claimForm: ClaimFormState;
	pages: PageListState;
	modal: GlobalModalState;
	addCssClass: GlobalCssClassState;
}

// Combine all reducers.
const appReducer = combineReducers({
	claimContent: claimContentSlice,
	customerParty: customerPartySlice,
	claimForm: claimFormSlice,
	pages: pageListSlice,
	modal: modalSlice,
	addCssClass: addCssClassSlice,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = (state: State | undefined, action: any) => {
	// Clear all data in redux store to initial.
	if (action.type === DESTROY_SESSION) state = undefined;

	return appReducer(state, action);
};
export default rootReducer;
