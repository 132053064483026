import { useAppSelector } from 'store/hooks';
import styles from './RadioList.module.scss';

import { Grid, GridCell } from '@almbrand/grid';
import { RadioCard } from '@almbrand/radiocard';
import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from '@almbrand/themeselector';
import { CustomInput } from './CustomInput';

export interface RadioItem extends WizardBaseListItem {
	defaultSelected?: boolean;
	text?: string;
	type?: string;
}

export interface RadioListProps extends WizardBaseList {
	label: string;
	allowCustomSelectItem: boolean;
	customSelectItemTag: string;
	customSelectItemLabel: string;
	selectItems: RadioItem[];
	hasError?: boolean;
	register: any;
	submitQuestion?: () => void;
}

export const RadioList: React.FC<RadioListProps> = ({
	fieldName = '',
	selectItems,
	required,
	label,
	validationErrorFormatText,
	hasError,
	register,
	submitQuestion,
	allowCustomSelectItem = false,
	customSelectItemLabel = 'Andet',
	customSelectItemTag = 'OTHER',
	regexValidation,
}) => {
	const fieldWatch = useAppSelector((store) => store.claimForm[fieldName]);

	const [currentRadioState, setCurrentRadioState] = useState<number>();

	const [inputValue, setInputValue] = useState('');
	const [inputHasError, setInputHasError] = useState(false);

	const theme = useContext(ThemeContext);

	useEffect(() => {
		const radioItem = fieldWatch?.data;
		if (radioItem?.index === undefined || radioItem?.index === null) return;
		if (currentRadioState !== radioItem.index) {
			handleOnChange(radioItem);
		}
		if (radioItem.tag == customSelectItemTag) {
			setInputValue(radioItem?.value);
		}
	}, [fieldWatch]);

	const { onChange: onRadioChange, name: radioName } = register(fieldName, {
		required,
	});

	const isMatch = (value: string, pattern: RegExp): boolean => {
		return pattern.test(value);
	};

	const handleOnChange = (radioItem: RadioItem) => {
		setCurrentRadioState(radioItem.index);

		if (radioItem.tag == customSelectItemTag) {
			if (!radioItem?.value || !isMatch(radioItem.value, new RegExp(regexValidation ?? ''))) {
				setInputHasError(true);
			}
		}

		if (radioItem.tag !== customSelectItemTag) {
			setInputValue('');
			setInputHasError(false);
		}

		onRadioChange({
			target: {
				name: radioName,
				value: {
					value: radioItem.value,
					label: radioItem.label,
					tag: radioItem.tag,
					index: radioItem.index,
					type: radioItem.type,
				},
			},
		});
	};

	const handleFocusEvent = (e: React.FocusEvent<HTMLInputElement>) => {
		handleOnChange({
			value: e.target.value,
			label: customSelectItemLabel,
			tag: customSelectItemTag,
			index: selectItems.length,
		});
	};

	return (
		<Grid container>
			<p>{label}</p>
			{selectItems?.map((radioItem, index) => (
				<GridCell
					key={radioItem.index}
					desktopWidth='12col'
					tabletWidth='12col'
					nogutter
				>
					<RadioCard
						themeName={theme}
						title={radioItem.label}
						subTitle={radioItem?.text}
						/*Some radio items might have the same value, its not relevant for the backend but the important for the user. 
							  Therefore we need to use index instead of value.*/
						value={index.toString()}
						onChange={() => {
							handleOnChange(radioItem);
							submitQuestion && !allowCustomSelectItem && submitQuestion();
						}}
						name={radioName}
						checked={currentRadioState === radioItem.index}
						onClick={() => {
							if (currentRadioState === radioItem.index) {
								submitQuestion && !allowCustomSelectItem && submitQuestion();
							}
						}}
					/>
				</GridCell>
			))}

			{(allowCustomSelectItem || !selectItems) && (
				<CustomInput
					label={customSelectItemLabel}
					onFocus={handleFocusEvent}
					value={inputValue}
					pattern={regexValidation}
					inputHasError={inputHasError}
					setInputHasError={setInputHasError}
					setValue={setInputValue}
				/>
			)}

			{(hasError || inputHasError) && (
				<p className={styles.RadioList__errorMessage}> {validationErrorFormatText} </p>
			)}
		</Grid>
	);
};
