import { critical } from 'services/ApplicationInsightsLog';
import { RootState, store } from 'store/store';

/**
 * Retrieves a value from the Redux store based on a composite key. The key can represent a nested
 * path, such as 'name' or 'person.tag'. If the desired property cannot be found, an empty string is returned.
 *
 * @param fieldId - The key representing the path to the desired property in the store state.
 * @returns The value from the store if found, otherwise an empty string.
 */
export const getStoreItem = (fieldId: string): string => {
	// Split the fieldId into parent object key and property key if a '.' is present.
	const [parentObjKey, propertyKey] = fieldId.includes('.') ? fieldId.split('.') : [fieldId];

	// Get the current state of the store.
	const state: RootState = store.getState();

	// Retrieve the relevant part of the state using the parent object key.
	const customerPartyItem = state.customerParty?.data[parentObjKey];
	const claimFormItem = state.claimForm[parentObjKey];

	// Determine the appropriate value based on the retrieved state parts.
	// Email or phone number can exist in both claimForm and customerParty.
	// ClaimForm has the updated version from the user, so we check claimFormItem first.

	if (claimFormItem) {
		return propertyKey
			? getNestedProperty(claimFormItem?.data, propertyKey)
			: (claimFormItem?.data?.value ?? claimFormItem?.data ?? '');
	}

	if (customerPartyItem) {
		return propertyKey ? getNestedProperty(customerPartyItem, propertyKey) : String(customerPartyItem);
	}
	// Return an empty string if the property cannot be found in the state.
	return '';
};

/**
 * Safely retrieves a property value from an object. If the property does not exist,
 * or the value is not a string, an empty string is returned.
 *
 * @param item - The object from which to retrieve the property value.
 * @param key - The key of the property to retrieve.
 * @returns The property value if it exists and is a string, otherwise an empty string.
 */
const getNestedProperty = (item: Record<string, any>, key: string): string => {
	if (typeof item === 'object' && item !== null && key in item) {
		const value = item[key];
		return typeof value === 'string' ? value : '';
	}
	return '';
};

/**
 * Find the claimFormItemName in the the claimForm, through the page name
 * @param claimForm ClaimForm or FormData
 * @param pageName optional search by pathName
 * @returns claimFormItemName or undefined
 */
export const getClaimFormItemName = (claimForm: any, pageName?: string) => {
	try {
		return Object.keys(claimForm).find((objKey) => {
			if (pageName) {
				return claimForm[objKey].page === pageName;
			}
			return true;
		});
	} catch (error) {
		// not found
		critical(
			error as Error,
			`Cannot find ClaimFormItemName, claimFOrm: ${claimForm?.toString()}, pageName: ${pageName} `
		);
		return;
	}
};
