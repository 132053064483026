import { InputTextField } from '@almbrand/inputtextfield';
import { TextArea } from '@almbrand/textarea';
import { ThemeContext } from '@almbrand/themeselector';
import { useContext } from 'react';
import { useAppSelector } from 'store/hooks';

export interface TextInputProps extends WizardComponentBase {
	placeholder: string;
	uiHint?: 'text' | 'textarea';
	stringCase?: string;
	hidden?: boolean;
	hasError?: boolean;
	register: any;
}

export const TextInput: React.FC<TextInputProps> = ({
	fieldName = '',
	regexValidation = '',
	inputId,
	required,
	label,
	validationErrorFormatText,
	readonly,
	placeholder,
	uiHint = 'text',
	hidden = false,
	hasError,
	register,
}) => {
	const fieldWatch = useAppSelector((store) => store.claimForm[fieldName]);
	const customerPartyValue = useAppSelector((store) => store.customerParty.data[fieldName]);

	const theme = useContext(ThemeContext);

	const registerProps = hidden
		? {}
		: register(fieldName, {
				required,
				pattern: new RegExp(regexValidation ?? ''),
			});

	const props = {
		...registerProps,
		id: inputId?.toString(),
		label: label,
		errorMessage: validationErrorFormatText,
		disabled: readonly,
		placeHolder: placeholder,
		hasError: hasError,
		icon: {
			iconProp: { themeName: theme },
		},
		value: fieldWatch?.data ?? customerPartyValue,
	};

	return !hidden && (uiHint == 'textarea' ? <TextArea {...props} /> : <InputTextField {...props} />);
};
