import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';

let appInsightsInstance: ApplicationInsights | null = null;

export const getAppInsightsInstance = (): ApplicationInsights | null => appInsightsInstance;
const setAppInsightsInstance = (instance: ApplicationInsights | null): void => {
	appInsightsInstance = instance;
};

const reactPlugin = new ReactPlugin();

export const initAppInsightsLogger = (): void => {
	if (window.location.hostname === 'localhost') {
		console.warn('Skipping Application Insights initialization in localhost environment.');
		return;
	}

	try {
		const connectionString = document.querySelector('#app')?.getAttribute('data-appinsights-key') ?? '';
		const currentUrl = window.location.href;

		if (!connectionString) {
			console.warn('Warning: Application Insights connection string is missing or invalid.');
			return;
		}

		// The instrumentation key is being deprecated as of 31.03.2025.
		// Make sure to replace this with the new connection string in the config below.
		// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react
		const appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: connectionString,
				enableAutoRouteTracking: true,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: { history: '' },
				},
				disableExceptionTracking: false,
				disableTelemetry: false,
				maxBatchInterval: 0,
			},
		});

		appInsights.loadAppInsights();
		setAppInsightsInstance(appInsights);

		const originalConsoleError = console.error;
		console.error = (...args: any[]) => {
			const errorMessage = args.map((arg) => (typeof arg === 'string' ? arg : JSON.stringify(arg))).join(' ');
			if (getAppInsightsInstance()) {
				getAppInsightsInstance()!.trackTrace({
					message: `Console error: ${errorMessage}`,
					severityLevel: SeverityLevel.Error,
					properties: { url: currentUrl },
				});
			}
			originalConsoleError(...args);
		};
	} catch (error) {
		console.error('Error initializing App Insights:', error);
		setAppInsightsInstance(null);
	}
};

export const isAppInsightsInitialized = (): boolean => {
	return !!getAppInsightsInstance() && typeof getAppInsightsInstance()!.trackTrace === 'function';
};

export const verbose = (message: string, properties: ICustomProperties | string): void => {
	if (isAppInsightsInitialized()) trace(message, SeverityLevel.Verbose, getProperties(properties));
};

export const information = (message: string, properties?: ICustomProperties | string): void => {
	if (isAppInsightsInitialized()) trace(message, SeverityLevel.Information, getProperties(properties));
};

export const warning = (message: string, properties?: ICustomProperties | string): void => {
	if (isAppInsightsInitialized()) trace(message, SeverityLevel.Warning, getProperties(properties));
};

export const critical = (exception: Error, message?: string, properties?: ICustomProperties | string): void => {
	if (isAppInsightsInitialized()) errorOrCritical(SeverityLevel.Critical, exception, message, properties);
};

export const exception = (exception: Error, message?: string, properties?: ICustomProperties | string): void => {
	if (isAppInsightsInitialized()) errorOrCritical(SeverityLevel.Error, exception, message, properties);
};

const errorOrCritical = (
	severityLevel: SeverityLevel,
	exception: Error,
	message?: string,
	properties?: ICustomProperties | string
): void => {
	if (!isAppInsightsInitialized()) return;
	const finalProperties = properties ? getProperties(properties) : { exception };
	const finalMessage = message ?? exception.name;
	getAppInsightsInstance()!.trackException({ exception, severityLevel }, finalProperties);
	trace(finalMessage, severityLevel, { ...finalProperties, exception });
};

const trace = (message: string, severityLevel: SeverityLevel, properties?: ICustomProperties): void => {
	if (!isAppInsightsInitialized()) return;
	getAppInsightsInstance()!.trackTrace({ message, severityLevel }, properties);
};

const getProperties = (properties: ICustomProperties | string | undefined): ICustomProperties | undefined => {
	if (!properties) return undefined;
	return typeof properties === 'string' ? { msg: properties } : properties;
};

export { AppInsightsErrorBoundary, reactPlugin };
