import { information } from 'services/ApplicationInsightsLog';
import { Rules } from './/validationHelpers/conditionEnums';
import { validateConditions } from './validationHelpers/conditionalValidators';
import { getStoreItem } from './validationHelpers/storeHandler';

export const evaluateConditions = (componentFieldName: string, conditionalField: ConditionalField) => {
	// get the main data from the conditionalField
	const { fieldId, andConditions } = conditionalField ?? {};
	let { fieldValue } = conditionalField ?? {};

	// check if fieldId is * and return true
	if (fieldValue == Rules.Any) return true;

	// check if there's a rule which applies to the current component (componentFieldId)
	const condition = conditionalField?.affectedFields?.find((field) => field == componentFieldName);
	if (!condition) return false;

	// check if Positive condition and remove the '!' in case its negative
	const isPositiveCondition = !fieldValue?.startsWith('!');
	if (!isPositiveCondition) fieldValue = fieldValue.substring(1);

	// getStoreItem handles both strings and objects
	const storeItem = getStoreItem(fieldId);

	// validate all conditions
	const isConditionValid = validateConditions(storeItem, fieldValue, isPositiveCondition);

	if (isConditionValid && andConditions) {
		andConditions?.map((condition) => {
			// get the store item for andCondition
			const storeItem = getStoreItem(condition);
			// validate the andCondition
			return validateConditions(storeItem, condition, isPositiveCondition);
		});
	}

	return isConditionValid;
};

export const executeCondition = (conditionAction: string): boolean => {
	// TODO: Apply cache clear
	switch (conditionAction) {
		case Rules.Clear:
			information(conditionAction);
			return true;

		case Rules.ClearAndHide:
			information(conditionAction);
			return false;

		case Rules.Hide:
			return false;

		default:
			return true;
	}
};
