import { PAGE_PARAM } from 'constants/pageParam';
import { useSearchParams } from 'react-router-dom';

export const usePageParam = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const setPageParam = (pageName: string) => {
		const newSearchParams = new URLSearchParams(searchParams.toString());
		newSearchParams.set(PAGE_PARAM, pageName);
		setSearchParams(newSearchParams);
	};

	const getPageParam = () => searchParams.get(PAGE_PARAM);

	return { setPageParam, getPageParam };
};
