import { evaluateNextUrl } from 'utilities/nextUrlValidator';

export const useNextUrl = (nextUrls?: Navigation['nextUrls']) => {
	const getNextUrl = async (): Promise<string> => {
		if (!nextUrls?.length) return '';
		const nextPath = await nextUrls.find((urlConditions) => evaluateNextUrl(urlConditions));
		return nextPath?.url ?? '';
	};

	return { getNextUrl };
};
