import { PageNames } from 'constants/pageNames';
import { useState } from 'react';
import { critical } from 'services/ApplicationInsightsLog';
import { postDataSource } from 'services/WizardServices';
import { useAppDispatch } from 'store/hooks';
import { addFormItem } from 'store/slices/claimFormSlice';
import { setGlobalModal } from 'store/slices/modalSlice';
import { addResultPage } from 'store/slices/pageListSlice';
import { getFormItemChanged } from 'utilities/validationHelpers/questionChangeValidator';
import { usePageParam } from './usePageParam';

export interface UseFormSubmitProps {
	path: string;
	submitUrl?: string;
	setNextUrl: () => Promise<void>;
	pageId?: number;
	disableChangeModal?: boolean;
}

export const useFormSubmit = ({ path, submitUrl, setNextUrl, pageId, disableChangeModal }: UseFormSubmitProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayModal, setDisplayModal] = useState<boolean>(false);
	const [changedItem, setChangedItem] = useState<any>(null);
	const dispatch = useAppDispatch();

	const { setPageParam } = usePageParam();

	const handleModal = (modal: boolean) => {
		setDisplayModal(modal);
		dispatch(setGlobalModal(modal));
	};

	const onSubmit = async (formData: any) => {
		const changedItem = getFormItemChanged(path, formData);
		setChangedItem(changedItem);
		if (formData) {
			Object.keys(formData).forEach((objKey) => {
				dispatch(
					addFormItem({
						key: objKey,
						item: { data: formData[objKey], page: path.replace('/', '') },
					})
				);
			});
		}

		if (changedItem && !disableChangeModal) {
			handleModal(true);

			return false;
		}

		if (submitUrl) {
			try {
				setIsLoading(true);
				const resultPageContent = await postDataSource(submitUrl, pageId);
				dispatch(addResultPage(resultPageContent?.resultPageHtml));
			} catch (error) {
				critical(error as Error);
				setPageParam(PageNames.ResultErrorPage);
				return false;
			} finally {
				setIsLoading(false);
			}
		}

		await setNextUrl();
	};

	return { onSubmit, isLoading, displayModal, handleModal, changedItem };
};
