import { Modal } from '@almbrand/modal';
import { usePageParam } from 'hooks/usePageParam';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ClaimFormItem, addFormItem, removeFormItem } from 'store/slices/claimFormSlice';
import { removePages } from 'store/slices/pageListSlice';
import { getClaimFormItemName } from 'utilities/validationHelpers/storeHandler';

export interface QuestionChangeModalProps {
	setModalOpen: (open: boolean) => void;
	pageData?: WizardPage;
	changedItem: ClaimFormItem;
	onChangeSuccessful?: () => Promise<void>;
}

export const QuestionChangeModal: React.FC<QuestionChangeModalProps> = ({
	setModalOpen,
	pageData,
	changedItem,
	onChangeSuccessful,
}) => {
	const { getPageParam, setPageParam } = usePageParam();
	const claimForm = useAppSelector((state) => state.claimForm);
	const pageList = useAppSelector((state) => state.pages);
	const dispatch = useAppDispatch();
	const { path } = pageData ?? {};

	const currentPageName = path?.replace('/', '') ?? '';

	/**
	 * remove claimItem data from store by pathName
	 */
	const removeClaimFormElements = async (pageName: string) => {
		if (currentPageName == pageName) return;
		const claimFormItemName = getClaimFormItemName(claimForm, pageName);
		if (claimFormItemName) {
			dispatch(removeFormItem({ key: claimFormItemName }));
		}
	};

	/**
	 * User want's to change an answer.
	 * Removes pages beneath this page and clears claimItem data in store for removed pages
	 */
	const handleChangeQuestion = async () => {
		const id = pageData?.id ?? pageList.activePages[0].id;
		const deleteFromIndex = pageList.activePages
			.map((page) => {
				return page.id;
			})
			.indexOf(id);

		// remove all subsequent data in claimForm including the currentPage
		const removePages_ = pageList.activePages.slice(deleteFromIndex);
		removePages_.forEach((page) => {
			removeClaimFormElements(page.path.replace('/', ''));
		});

		// delete all pages after currentPage (deleteFromIndex + 1)
		dispatch(removePages(deleteFromIndex + 1));

		setModalOpen(false);

		// make sure that the Url changes, so new value will be submitted and nextUrl resolved
		const path_ = path?.replace('/', '');

		if (path_ && path_ !== getPageParam()) {
			setPageParam(path_);
		}
		onChangeSuccessful && (await onChangeSuccessful());
	};

	/**
	 * reset component value to previous value, since the user has chosen abort changes
	 */
	const handleIgnoreChange = () => {
		if (path) {
			const claimFormItemName = getClaimFormItemName(claimForm, currentPageName);
			if (claimFormItemName) {
				dispatch(
					addFormItem({
						key: claimFormItemName,
						item: { data: changedItem?.data, page: currentPageName },
					})
				);
			}
		}
		setModalOpen(false);
	};

	const handleClose = () => handleIgnoreChange();
	const handleAction = () => handleChangeQuestion();

	return (
		<Modal
			title='Er du sikker på, at du vil ændre dit svar?'
			closeText='Nej, behold mit svar'
			actionText='Ja, redigér mit svar'
			ariaClose='Close'
			handleClose={handleClose}
			handleAction={handleAction}
			actionButtonType='primary'
			closeButtonType='tertiary'
			closeIconSize={40}
		>
			{
				<>
					Hvis du ændrer dit svar på dette spørgsmål, kan det have indflydelse på dine andre besvarelser.
					<br></br>
					<br></br>
					De spørgsmål du har besvaret efter dette spørgsmål, vil derfor blive slettet, og du skal besvare
					disse igen.
				</>
			}
		</Modal>
	);
};
