import classNames from 'classnames';
import React, { Children, ReactNode, useEffect, useRef, useState } from 'react';
import style from './ScrollComponent.module.scss';
import { useScrollDirection } from './hooks/useScrollDirection';

type ScrollComponentProps = {
	children: ReactNode[];
	activeElement?: number;
	delay?: number; //Alter the scrolling length before scrolling occurs
	targetClass?: string; //Alternative class where overflow will occur
	onIndexChange?: (newIndex: number, child?: ReactNode) => void; // Callback for index change
};

export const ScrollComponent: React.FC<ScrollComponentProps> = ({
	children,
	activeElement = 0,
	delay,
	targetClass,
	onIndexChange,
}) => {
	const concatTargetClass = targetClass ? `${style.show} ${targetClass}` : style.show;
	const scrollComponentRef = useRef(null);
	const { state } = useScrollDirection({ element: scrollComponentRef, targetClass: concatTargetClass, delay: delay });
	const [index, setIndex] = useState(activeElement);

	useEffect(() => {
		setIndex(activeElement);
	}, [activeElement]);

	useEffect(() => {
		switch (true) {
			case state.direction === 'DOWN' && index < children.length - 1:
				const nextIndex = index + 1;
				setIndex(nextIndex);
				onIndexChange?.(nextIndex, children[nextIndex]);
				break;
			case state.direction === 'UP' && index > 0:
				const prevIndex = index - 1;
				setIndex(prevIndex);
				onIndexChange?.(prevIndex, children[prevIndex]);
				break;
			default:
				console.warn('Unexpected state: No scrolling detected or invalid conditions');
		}
	}, [state]);

	const mapContent = () => {
		return Children.map(children, (child, i) => {
			return (
				<div
					className={classNames(
						style.ScrollComponent__animate,
						i === index ? style.show : i < index ? style.hideUp : style.hideDown
					)}
				>
					{child}
				</div>
			);
		});
	};

	return (
		<div
			ref={scrollComponentRef}
			className={style.ScrollComponent}
		>
			{mapContent()}
		</div>
	);
};
